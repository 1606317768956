<template>
    <div :class="showProgress == true ? 'loading' : ''">
        <!-- <el-upload drag :multiple="false" :auto-upload="true" :http-request="checkedFile" :before-remove="removeFile"
            :limit="1" action="*" :disabled="showProgress">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                将⽂件拖到此处，或
                <em>点击上传</em>
            </div>
        </el-upload> -->
        <input type="file" @change="checkedFile" />
        <!-- 正在上传的弹窗 -->
        <el-dialog title="正在上传" :visible.sync="showProgress" width="50%">
            <el-progress type="circle" :percentage="progress" class="progress" v-if="showProgress"></el-progress>
        </el-dialog>
        <!-- <el-progress type="circle" :percentage="progress" class="progress" v-if="showProgress"></el-progress> -->
    </div>
</template>
<script>
import axios from "axios";
import SparkMD5 from "spark-md5";
import { uploadFileupload, uploadFilemerge } from "@/api";
export default {
    data() {
        return {
            eachSize: 10 * 1024 * 1024, // 每块⽂件⼤⼩ 100mb
            requestCancelQueue: [], // 请求⽅法队列（调⽤取消上传
            //上传进度
            progress: 0,
            showProgress: false,
            // 每上传⼀块的进度
            eachProgress: 0,
            // 总共有多少块。断点续传使⽤
            chunksKeep: 0,
            // 切割后的⽂件数组
            fileChunksKeep: [],
            // 这个⽂件，断点续传
            fileKeep: null,
            // 断点续传，⽂件md5
            fileMd5Keep: "",
            timers: ''
        };
    },
    mounted() {

    },
    methods: {
        generateMixed(n) {
            var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
                'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
                'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
            var res = "";
            for (var i = 0; i < n; i++) {
                var id = Math.floor(Math.random() * 36);
                res += chars[id];
            }
            return res;
        },
        async checkedFile(e) {
            const [file] = e.target.files;
            this.timers = this.generateMixed(6) + new Date().getTime()
            this.fileKeep = file;
            try {
                let res = await this.splitUpload(file);
                console.log(res);
            } catch (e) {
                console.error(e);
                this.$message({
                    message: e.message,
                    type: "error"
                });
                this.showProgress = false;
                this.progress = 0;
            }
            const prom = new Promise((resolve, reject) => { }); // 上传后返回⼀个promise
            prom.abort = () => { };
            return prom;
        },
        // ⼤⽂件分块上传
        splitUpload(file) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { eachSize } = this;
                    const chunks = Math.ceil(file.size / eachSize);
                    this.chunksKeep = chunks;
                    const fileChunks = await this.splitFile(file, eachSize);
                    this.fileChunksKeep = fileChunks;
                    console.log(fileChunks);
                    console.log("fileChunks,⽂件数组切割后");
                    //判断每上传⼀个⽂件，进度条涨多少,保留两位⼩数
                    this.eachProgress = parseInt(Math.floor((100 / chunks) * 100) / 100);
                    this.showProgress = true;
                    let currentChunk = 0;
                    let newarr = []
                    let newfileChunks = []
                    // Array.from(fileChunks)
                    let currentChunkMd5 = 0;
                    for (let i = 0; i < fileChunks.length; i++) {
                        let obj = {
                            index: i + 1,
                            blob: fileChunks[i]
                        }
                        newfileChunks.push(obj)
                    }
                    for (let i = 0; i < fileChunks.length; i++) {
                        if (newfileChunks.length) {
                            let arr = newfileChunks.slice(0, 5)
                            newfileChunks.splice(0, 5)
                            newarr.push(arr)
                        }
                    }
                    for (let i = 0; i < newarr.length; i++) {
                        let newpro = newarr[i].map(async (arrItem) => {
                            return await this.postFile(
                                {
                                    chunked: true,
                                    chunk: arrItem.index,
                                    chunks,
                                    eachSize,
                                    fileName: file.name,
                                    fullSize: arrItem.blob.size,
                                    uid: file.uid,
                                    file: arrItem.blob
                                }).then(res => {
                                    currentChunk++;
                                    // 上传完⼀块后，进度条增加
                                    this.progress += this.eachProgress;
                                    // 不能超过100
                                    this.progress = this.progress > 100 ? 100 : this.progress;
                                    currentChunkMd5++
                                })
                        })
                        await Promise.race(newpro)
                    }
                    console.log(fileChunks);
                    const spark = new SparkMD5.ArrayBuffer();
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                        spark.append(e.target.result);
                        if (currentChunkMd5 < chunks) {
                            loadNext();
                        } else {
                            console.log(12321321312);
                            uploadFilemerge(this.timers).then(res => {
                                resolve(res);
                            })
                        }
                    };
                    async function loadNext() {
                        const start = currentChunkMd5 * eachSize;
                        const end =
                            start + eachSize >= file.size ? file.size : start + eachSize;
                        await reader.readAsArrayBuffer(file.slice(start, end));
                    }
                    this.$message({
                        message: "正在进⾏⽂件加密校验",
                        type: "info"
                    });
                    await loadNext();
                } catch (error) {
                    reject(error);
                }
            });
        },
        // ⽂件分块,利⽤Array.prototype.slice⽅法
        splitFile(file, size) {
            const fileChunkList = [];
            let cur = 0;
            let filenowsize = file.size;
            while (cur < file.size) {

                if (filenowsize > size) {
                    filenowsize = filenowsize - size;
                    fileChunkList.push(file.slice(cur, cur + size));
                } else {
                    fileChunkList.push(file.slice(cur, cur + size));
                }
                cur += size;
            }
            return fileChunkList;
        },
        blobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    resolve(e.target.result);
                };
                // readAsDataURL
                fileReader.readAsDataURL(blob);
                fileReader.onerror = () => {
                    reject(new Error("blobToBase64 error"));
                };
            });
        },
        // 提交⽂件⽅法,将参数转换为FormData, 然后通过axios发起请求
        async postFile(param) {
            // const formData = new FormData();
            let formData = {}
            // for (let p in param) {
            // formData.append(p, param[p]);
            // }
            console.log(param);
            formData.shard = await this.blobToBase64(param.file);
            formData.fileKey = param.fileName;
            formData.name = param.fileName;
            formData.shardIndex = param.chunk;
            formData.shardSize = param.fullSize;
            formData.fileUse = this.timers
            formData.suffix = param.chunk
            formData.shardTotal = param.chunks
            const { requestCancelQueue } = this;
            const config = {
                cancelToken: new axios.CancelToken(function executor(cancel) {
                    if (requestCancelQueue[param.uid]) {
                        requestCancelQueue[param.uid]();
                        delete requestCancelQueue[param.uid];
                    }
                    requestCancelQueue[param.uid] = cancel;
                }),
                onUploadProgress: e => {
                    if (param.chunked) {
                        e.percent = Number(
                            (
                                ((param.chunk * (param.eachSize - 1) + e.loaded) /
                                    param.fullSize) *
                                100
                            ).toFixed(2)
                        );
                    } else {
                        e.percent = Number(((e.loaded / e.total) * 100).toFixed(2));
                    }
                }
            };
            console.log(formData);
            return await uploadFileupload(formData)
            // return this.$http({
            //     url: "/tools/upload_chunk/",
            //     method: "POST",
            //     data: formData
            //     // config
            // }).then(rs => rs.data);
        },
    }
};
</script>
<style scoped>
.loading {
    /* 整体⻚⾯置灰 */
    /* background: rgba(0, 0, 0, 0.5); */
}

.progress {
    /* 在当前⻚⾯居中 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 40px;
    /* 宽度 */
}

.el-dialog {
    position: relative;
    height: 500px;
}
</style>